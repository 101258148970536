// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delivery-ke-cz-index-jsx": () => import("./../../../src/pages/delivery-ke-cz/index.jsx" /* webpackChunkName: "component---src-pages-delivery-ke-cz-index-jsx" */),
  "component---src-pages-delivery-ke-ke-index-jsx": () => import("./../../../src/pages/delivery-ke-ke/index.jsx" /* webpackChunkName: "component---src-pages-delivery-ke-ke-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-file-pdf-jsx": () => import("./../../../src/pages/file/pdf.jsx" /* webpackChunkName: "component---src-pages-file-pdf-jsx" */),
  "component---src-pages-for-companies-index-jsx": () => import("./../../../src/pages/for-companies/index.jsx" /* webpackChunkName: "component---src-pages-for-companies-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ke-cz-index-jsx": () => import("./../../../src/pages/ke-cz/index.jsx" /* webpackChunkName: "component---src-pages-ke-cz-index-jsx" */),
  "component---src-pages-ke-ke-index-jsx": () => import("./../../../src/pages/ke-ke/index.jsx" /* webpackChunkName: "component---src-pages-ke-ke-index-jsx" */),
  "component---src-pages-test-cookie-index-jsx": () => import("./../../../src/pages/test-cookie/index.jsx" /* webpackChunkName: "component---src-pages-test-cookie-index-jsx" */),
  "component---src-pages-test-cookie-setting-index-jsx": () => import("./../../../src/pages/test-cookie-setting/index.jsx" /* webpackChunkName: "component---src-pages-test-cookie-setting-index-jsx" */),
  "component---src-pages-thank-you-index-jsx": () => import("./../../../src/pages/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-thank-you-index-jsx" */)
}

